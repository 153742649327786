import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'

import { DetailContainer } from 'containers'
import { useStock } from 'hooks'

import Filter from './Filter'
import { helix } from 'ldrs'

const initialQuery = {
  serial_number: '',
}

const BuscadorSerie = () => {
  const { loading, handleGetArticuloPorSerie } = useStock()
  const [query, setQuery] = useState(initialQuery)
  const [serie, setSerie] = useState(null)

  const getSerie = useCallback(async () => {
    try {
      let params = {
        ...query,
      }
      const response = await handleGetArticuloPorSerie(params)
      const data = response.data.result

      setSerie(data)
    } catch (error) {
      setSerie(null)
      toast.error(error.message)
    }
  }, [query, handleGetArticuloPorSerie])

  useEffect(() => {
    getSerie()
  }, [getSerie])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <DetailContainer title='Buscador por serie'>
      <Filter loading={loading} query={query} setQuery={setQuery} />

      {loading && !serie && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

      {serie &&
        <div className='row mt-4 fs-6'>
          <div className='separator my-4'></div>

          <div className='col-6'>
            <p>Artículo: <strong>[{serie?.product_var_id}] {serie?.product_var_name}</strong></p>
            <p>GTIN: <strong>{serie?.gtin}</strong></p>
            <p>Nro. de serie: <strong>{serie?.serial_number}</strong></p>
            <p>Depósito: <strong>{serie?.storage_space_name}</strong></p>
            <p>Lote: <strong>{serie?.lot_number}</strong> - Venc.: {moment(serie?.expiration_date).format('MM/YYYY')}</p>
          </div>

          <div className='col-6'>
            <p>Disponible: {serie?.a}</p>
            <p>No disponible: {serie?.na}</p>
            <p>Reservado: {serie?.r}</p>
            <p>Pickeado: {serie?.p}</p>
            <p>Egreso: {serie?.o}</p>
          </div>

          <p><strong className='text-primary'>Ingresó</strong> con la <strong>Orden de ingreso #{serie?.in_order_id}</strong> el {moment(serie?.in_order_date).format('DD/MM/YYYY')}</p>

          {serie?.out_order_id &&
            <p><strong className='text-danger'>{serie?.r == 1 || serie?.p == 1 ? `Está ${serie?.r == 1 ? 'reservado' : 'pickeado'}` : 'Salió'}</strong> en la <strong>Orden de egreso #{serie?.out_order_id}</strong></p>
          }
        </div>
      }
    </DetailContainer>
  )
}

export default BuscadorSerie